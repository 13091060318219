const whitelistFields = [
  'mobile_phone',
  'full_name',
  'document_number',
  'date_of_birth',
  'email',
  'living_street_house_apt',
  'guarantor_phone',
  'bank_account_number',
  'bank_card_number',
  'branch_name',
  'otp_mobile_phone'
]

export default class LibDataBehaviorCollector {
  constructor(container) {
    this.container = container

    // focusin
    $('input').on('focusin', (e) => {
      let fieldName = this.getFieldName(e)
      if (!whitelistFields.includes(fieldName)) return

      let application = JSON.parse(localStorage.getItem('application')) || {}

      if (application[fieldName] === undefined) this.setDefaultKeys(application, fieldName)

      localStorage.setItem('application', JSON.stringify(application))
    }).on('focusout', (e) => {
      let fieldName = this.getFieldName(e)
      if (!whitelistFields.includes(fieldName)) return

      let application = JSON.parse(localStorage.getItem('application'))

      if (application[fieldName] === undefined) this.setDefaultKeys(application, fieldName)

      this.setTimeChanges(application[fieldName])
      this.setOnFocusValueChanges(application[fieldName], e.target.value)

      this.setCriticalValueChanges(application[fieldName])

      localStorage.setItem('application', JSON.stringify(application))

      if (location.pathname !== '/') {
        $.ajax({
          type: 'PATCH',
          dataType: 'json',
          url: '/application/behavioral_variables',
          data: {
            application: application
          }
        })
      }
    }).on('copy', (e) => {
      const fieldName = this.getFieldName(e)
      if (!whitelistFields.includes(fieldName)) return

      let application = JSON.parse(localStorage.getItem('application'))

      if (application[fieldName] === undefined) this.setDefaultKeys(application, fieldName)

      application[fieldName].number_of_use_copy += 1

      localStorage.setItem('application', JSON.stringify(application))
    }).on('paste', (e) => {
      const fieldName = this.getFieldName(e)

      if (!whitelistFields.includes(fieldName)) return

      let application = JSON.parse(localStorage.getItem('application'))

      if (application[fieldName] === undefined) this.setDefaultKeys(application, fieldName)

      application[fieldName].number_of_use_paste += 1

      this.setCriticalValueChanges(application[fieldName])

      localStorage.setItem('application', JSON.stringify(application))
    }).on('input', (e) => {
      const fieldName = this.getFieldName(e)
      if (!whitelistFields.includes(fieldName)) return

      let application = JSON.parse(localStorage.getItem('application'))

      if (application[fieldName] === undefined) this.setDefaultKeys(application, fieldName)

      application[fieldName].prevValue = application[fieldName].currentValue
      application[fieldName].currentValue = this.prepareValue(e.target.value)

      if (application[fieldName].currentValue.length < application[fieldName].prevValue.length) {
        application[fieldName].number_of_backspace += 1
        this.setCriticalValueChanges(application[fieldName])
      }

      localStorage.setItem('application', JSON.stringify(application))
    })

    $(window).blur(() => {
      let step = this.currentStep()

      if (step !== null && typeof step !== undefined) {
        let application = JSON.parse(localStorage.getItem('application'))

        let stepName = 'page' + step
        if (application[stepName] === undefined) {
          application[stepName] = {
            count_of_leave: 0,
            count_of_prev_page: 0
          }
        }

        application[stepName].count_of_leave += 1

        localStorage.setItem('application', JSON.stringify(application))
      }
    })

    this.checkPrevPage()
  }

  checkPrevPage() {
    let step = this.currentStep()

    if (typeof step !== "number") return

    let application = JSON.parse(localStorage.getItem('application'))

    if (application === null) application = {}
    if (application.currentStep === undefined) application.currentStep = 1

    const pageName = 'page' + application.currentStep

    if (application[pageName] === undefined) {
      application[pageName] = {
        count_of_leave: 0,
        count_of_prev_page: 0
      }
    }

    if (application.currentStep - step === 1) application[pageName].count_of_prev_page += 1

    application.currentStep = step

    localStorage.setItem('application', JSON.stringify(application))
  }

  setOnFocusValueChanges(field, value) {
    value = this.prepareValue(value)

    if (value !== field.prevFocusValue && field.prevFocusValue !== '') field.number_of_corrections += 1

    field.number_of_patterns_2_symbols = this.calculateSubseq(value, 2)
    field.number_of_patterns_3_symbols = this.calculateSubseq(value, 3)
    field.number_of_patterns_4_symbols = this.calculateSubseq(value, 4)
    field.prevFocusValue = value
    field.prevValue = value
  }

  setCriticalValueChanges(field) {
    const minSize = Math.min(field.prevCriticalEventValue.length, field.prevValue.length)
    const preventSubstr = field.prevCriticalEventValue.substr(0, minSize)
    const currentSubstr = field.prevValue.substr(0, minSize)

    if (field.prevCriticalEventValue === '') {
      field.prevCriticalEventValue = field.prevValue
    } else if (preventSubstr !== currentSubstr) {
      field.number_of_all_changes += 1

      const levDist = this.levensteinDistance()(preventSubstr, currentSubstr)

      if (levDist >= 2) field.number_of_critical_changes += 1

      field.prevCriticalEventValue = field.prevValue
    }
  }

  setTimeChanges(field) {
    field.endTime = Date.now()

    const diffTime = Math.round(((field.endTime - field.startTime) / 1000))
    const active = diffTime > 10 ? 10 : diffTime
    const sleep = diffTime > 10 ? diffTime - 10 : 0

    field.time_of_filling_field += active
    field.time_of_sleep += sleep
  }

  calculateSubseq(value, countOfSeq) {
    let seqStrings = Array.from(new Set(this.eachCons(value, countOfSeq)))
    let total = 0

    seqStrings.map(function (seq) {
      let regexp = new RegExp(seq, "g")
      let cntMatched = (value.match(regexp) || []).length

      cntMatched = cntMatched > 1 ? cntMatched : 0
      total += cntMatched
    })

    return total
  }

  getFieldName(e) {
    if (this.stepsPath() && e.target.id.includes('mobile_phone')) {
      return e.target.id.replace('application_', 'otp_')
    }

    return e.target.id.replace('application_', '')
  }

  currentStep() {
    if (this.stepsPath()) {
      const step = location.pathname.split('/').pop()
      return parseInt(step)
    } else {
      return null
    }
  }

  eachCons(array, num) {
    return Array.from({length: array.length - num + 1}, function (_, i) {
      return array.slice(i, i + num)
    })
  }

  // https://github.com/gustf/js-levenshtein/blob/master/index.js
  levensteinDistance() {
    function _min(d0, d1, d2, bx, ay) {
      return d0 < d1 || d2 < d1
        ? d0 > d2
          ? d2 + 1
          : d0 + 1
        : bx === ay
          ? d1
          : d1 + 1;
    }

    return function(a, b) {
      if (a === b) {
        return 0
      }

      if (a.length > b.length) {
        let tmp = a
        a = b
        b = tmp
      }

      let la = a.length
      let lb = b.length

      while (la > 0 && (a.charCodeAt(la - 1) === b.charCodeAt(lb - 1))) {
        la--
        lb--
      }

      let offset = 0

      while (offset < la && (a.charCodeAt(offset) === b.charCodeAt(offset))) {
        offset++
      }

      la -= offset
      lb -= offset

      if (la === 0 || lb < 3) {
        return lb
      }

      let x = 0
      let y, d0, d1, d2, d3, dd, dy, ay, bx0, bx1, bx2, bx3
      let vector = []

      for (y = 0; y < la; y++) {
        vector.push(y + 1)
        vector.push(a.charCodeAt(offset + y))
      }

      let len = vector.length - 1;

      for (; x < lb - 3;) {
        bx0 = b.charCodeAt(offset + (d0 = x))
        bx1 = b.charCodeAt(offset + (d1 = x + 1))
        bx2 = b.charCodeAt(offset + (d2 = x + 2))
        bx3 = b.charCodeAt(offset + (d3 = x + 3))
        dd = (x += 4)
        for (y = 0; y < len; y += 2) {
          dy = vector[y]
          ay = vector[y + 1]
          d0 = _min(dy, d0, d1, bx0, ay)
          d1 = _min(d0, d1, d2, bx1, ay)
          d2 = _min(d1, d2, d3, bx2, ay)
          dd = _min(d2, d3, dd, bx3, ay)
          vector[y] = dd
          d3 = d2
          d2 = d1
          d1 = d0
          d0 = dy
        }
      }

      for (; x < lb;) {
        bx0 = b.charCodeAt(offset + (d0 = x))
        dd = ++x
        for (y = 0; y < len; y += 2) {
          dy = vector[y]
          vector[y] = dd = _min(dy, d0, dd, bx0, vector[y + 1])
          d0 = dy
        }
      }

      return dd
    }
  }

  prepareValue(value) {
    return value.replace(/[X _-]/g, '')
  }

  stepsPath() {
    return /steps/.test(location.pathname)
  }

  setDefaultKeys(application, fieldName) {
    application[fieldName] = {
      startTime: Date.now(),
      prevFocusValue: '',
      prevValue: '',
      currentValue: '',
      prevCriticalEventValue: '',
      time_of_filling_field: 0,
      time_of_sleep: 0,
      number_of_use_copy: 0,
      number_of_use_paste: 0,
      number_of_backspace: 0,
      number_of_corrections: 0,
      number_of_all_changes: 0,
      number_of_critical_changes: 0
    }
  }
}
