import { range } from 'ext/utils'
import 'ext/patching'
import Slider from 'bootstrap-slider'
import Cookies from 'js-cookie'
const { remove, set } = Cookies
const { calculator, product, translations } = window.gon
const TERM_VALUES = calculator.term
const TERM_MIN = TERM_VALUES[0]
const TERM_MAX = TERM_VALUES[TERM_VALUES.length - 1]
const CONDITIONAL_VALUE = calculator.amountLimitation
let LOAN_VALUES = calculator.amount
let LOAN_MIN = LOAN_VALUES[0]
let LOAN_MAX = LOAN_VALUES[LOAN_VALUES.length - 1]
const DENOMINATOR = calculator.denominator
const FEE_AMOUNT = calculator.feeProductPercent
const COMMISSIONS = calculator.commissions
const DEFAULT_COMMISSIONS = calculator.commissionsFromDefaultProduct
const PRODUCT_WEEKEND = product.weekend
const AMOUNT_STEP = (LOAN_MAX - LOAN_MIN) / LOAN_VALUES.length
const TERM_STEP = (TERM_MAX - TERM_MIN) / TERM_VALUES.length
const FDF_AMOUNT = calculator.fdfAmount

export default class LibCalculator {
  constructor(container, position = 'goHero', options = {}) {
    if (!container) return

    this.AMOUNT_SLIDER = null
    this.TERM_SLIDER = null

    const { skipListeners = false } = options
    this.container = container
    this.position = position
    this.loanWidget = container.querySelector('[role="loanWidget"]')
    this.amountSliderIdent = '[role="amountSlider"]'
    this.amountSlider = container.querySelector(this.amountSliderIdent)
    this.amountSliderMove = container.querySelector('[role="amountSliderMove"]')
    this.amountSliderMoveToRepeat = container.querySelector('[role="amountSliderMoveToRepeat"]')
    this.defaultAmountWas = container.querySelector('[role="defaultAmountWas"]')
    this.defaultAmountChangedTo = container.querySelector('[role="defaultAmountChangedTo"]')
    this.maximumAmountShown = container.querySelector('[role="maximumAmountShown"]')

    this.termSliderIdent = '[role="termSlider"]'
    this.termSlider = container.querySelector(this.termSliderIdent)
    this.showAmountSlider = this.amountSlider?.getAttribute('data-show-slider') === 'true'
    this.showTermSlider = this.termSlider?.getAttribute('data-show-slider') === 'true'

    this.termSliderMove = container.querySelector('[role="termSliderMove"]')

    this.creditLimitation = container.querySelector('[role="creditLimitation"]')
    this.totalValue = container.querySelector('[role="totalValue"]')

    this.sliderCollapse = container.querySelector('[role="sliderCollapse"]')
    this.calcWidgetButton = container.querySelector('[role="calcWidgetButton"]')

    this.needAmount = container.querySelector('[role="needAmount"]')
    this.feeAmount = container.querySelector('[role="feeAmount"]')
    this.needAmountMobile = container.querySelector('[role="needAmountMobile"]')
    this.forTerms = container.querySelector('[role="forTerms"]')

    this.calcAmount = container.querySelector('[role="calcAmount"]')
    this.calcAmountSum = container.querySelector('[role="calcAmountSum"]')
    this.calcInterest = container.querySelector('[role="calcInterest"]')
    this.oldCalcTotal = container.querySelector('[role="oldCalcTotal"]')
    this.calcTotal = container.querySelector('[role="calcTotal"]')
    this.calcHandingFee = container.querySelector('[role="calcHandingFee"]')
    this.paymentDueDateLabel = container.querySelector('[role="paymentDueDateLabel"]')
    this.sliderCollapse = container.querySelector('[role="sliderCollapse"]')
    this.loanInformationItems = container.querySelector('[role="loanInformationItems"]')

    this.fdfIsAgreed = container.querySelector('[data-ref="fdf_is_agreed"]')

    const sliderCollapseLink = container.querySelector('[role="sliderCollapseLink"]')

    if (this.loanWidget) this.setLoanLimitation()

    this.initializeSliders()
    this.countCalcValues({})

    if (!skipListeners) {
      sliderCollapseLink?.addEventListener('click', function (e) {
        e.preventDefault()
        const target = $(e.currentTarget)
        this.sliderCollapse.classList.toggle('hidden')

        if (this.showAmountSlider) this.AMOUNT_SLIDER.relayout()
        if (this.showTermSlider) this.TERM_SLIDER.relayout()

        target.toggleClass('in')
        const original = target.data('original')
        const replaceText = target.data('replace')

        if (target.hasClass('in'))
          target.html(replaceText)
        else
          target.html(original)

        this.loanInformationItems.classList.toggle('hidden')

        remove('termSliderWas')
        // if (this.showTermSlider) this.TERM_SLIDER.setValue(value, false, true)
      }.bind(this))
    }

    this.fdfIsAgreed?.addEventListener('fdfAgreed', function (_e) {
      const total = Number(this.calcTotal.innerText.replaceAll(/\s|,/g, '')) + FDF_AMOUNT
      this.calcTotal.innerText = `${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} `
    }.bind(this))

    this.fdfIsAgreed?.addEventListener('change', function (e) {
      if (e.target?.checked) return false

      const total = Number(this.calcTotal.innerText.replaceAll(/\s|,/g, '')) - FDF_AMOUNT
      this.calcTotal.innerText = `${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} `
    }.bind(this))
  }

  setDefaultAmountMoved(defaultAmountWas, newAmount) {
    let newEnum = 0

    if (defaultAmountWas < newAmount)
      newEnum = 1
    else if (defaultAmountWas > newAmount)
      newEnum = -1

    this.defaultAmountChangedTo.value = newEnum
  }

  setLoanLimitation() {
    LOAN_VALUES = calculator.amount.filter(v => v <= CONDITIONAL_VALUE)
    LOAN_MIN = LOAN_VALUES[0]
    LOAN_MAX = LOAN_VALUES[LOAN_VALUES.length - 1]
  }

  initializeSliders() {
    if (document.body.contains(this.container.querySelector('.slider-track')))
      this.container.querySelector('.slider.slider-horizontal').remove()

    if (this.amountSlider && this.showAmountSlider && !this.AMOUNT_SLIDER) {
      this.calculatePaymentDueDate()
      if (!this.showAmountSlider) return

      if (this.creditLimitation) this.creditLimitation.innerText = translations['js.slider.tip']
      // const ticks = LOAN_VALUES.filter(v => v <= CONDITIONAL_VALUE)

      this.AMOUNT_SLIDER = new Slider(this.amountSliderIdent, {
        min: LOAN_MIN,
        max: LOAN_MAX,
        ticks: LOAN_VALUES,
        ticks_positions: this.calcPositionAmount(LOAN_VALUES),
        ticks_labels: this.makeLabels(LOAN_VALUES),
        tooltip: 'hide',
        formatter: value => {
          const tipText = value > CONDITIONAL_VALUE ? translations['js.slider.tip'] : ''
          return `${value.format(0, ',', ' ')}\xa0VND \n ${tipText}`
        }
      })

      this.AMOUNT_SLIDER.on('slideStop', newValue => {
        const newValueScale = this.amountScale(LOAN_VALUES, newValue)
        this.AMOUNT_SLIDER.setValue(newValueScale)
      })

      this.AMOUNT_SLIDER.on('change', this.amountSliderChanging.bind(this))

      if (this.maximumAmountShown) this.maximumAmountShown.value = CONDITIONAL_VALUE
      if (this.defaultAmountWas) this.defaultAmountWas.value = this.amountSlider.value

      let needAmount = parseInt(this.amountSlider.value, 10)

      let feeAmount = needAmount * FEE_AMOUNT / 100

      if (this.fdfIsAgreed?.checked) needAmount += FDF_AMOUNT

      if (this.needAmountMobile) this.needAmountMobile.innerText = `${needAmount / 1000000} `
    }

    if (this.termSlider && this.showTermSlider && !this.TERM_SLIDER) {
      this.TERM_SLIDER = new Slider(this.termSliderIdent, {
        min: TERM_MIN,
        max: TERM_MAX,
        step: 0.01,
        ticks: TERM_VALUES,
        ticks_positions: this.calcPosition(TERM_VALUES),
        ticks_labels: this.makeTicks(TERM_VALUES),
        tooltip: 'hide',
        formatter: value => `${value} days`
      })

      this.TERM_SLIDER.on('slideStop', newValue => {
        const newValueScale = this.termScale(TERM_VALUES, newValue)
        this.TERM_SLIDER.setValue(newValueScale)
      })

      this.TERM_SLIDER.on('change', this.termSliderChanging.bind(this))
    }
  }

  amount_slider_move() {
    this.amountSliderMove.value = 1
  }

  term_slider_move() {
    this.termSliderMove.value = 1
  }

  amount_slider_move_to_repeat() {
    this.amountSliderMoveToRepeat.value = 1
  }

  timeout() {
    const total = this.position === 'goSteps' ? this.calcAmount : this.totalValue

    total.classList.add('green')

    setTimeout(() => {
      total.classList.remove('green')
    }, 500)
  }

  calculatePaymentDueDate(term) {
    const value = parseFloat(term || this.termSlider.value)
    const currentDate = new Date()
    const date = currentDate.addDays(value)
    if (this.paymentDueDateLabel)
      this.paymentDueDateLabel.innerText = `${date.getDate().padLeft()}/${(date.getMonth() + 1).padLeft()}/${date.getFullYear()}`
  }

  makeLabels(array) {
    return array.map(num => {
      if (num === array[0] || num === array[array.length - 1])
        return `${num.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') } ₫`
      else
        return ''
    })
  }

  makeTicks(array) {
    return array.map(num => {
      if (num === array[0] || num === array[array.length - 1])
        return `${num.toString()} ngày`
      else
        return ''
    })
  }

  calcPositionAmount(array) {
    const first = array[0]
    const last = array[array.length - 1]
    const range_max = 100
    const step = range_max / (array.length - 1)
    const positions = range(0, range_max, step)
    positions.push(range_max)
    return positions
  }

  calcPosition(array) {
    const range_max = 100

    if (array.length < 2) {
      return [0, 100]
    } else {
      const step = range_max / (array.length - 1)
      const positions = range(0, range_max).filter(v => v % step === 0)
      positions.push(range_max)
      return positions
    }
  }

  countCalcValues({ amount, term }) {
    amount = parseFloat(amount || this.amountSlider?.value)
    term = parseFloat(term || this.termSlider?.value)
    const daysCount = parseFloat(this.termSlider?.value)
    if (!amount) return false

    const handingFee = amount - (amount * 0.1)
    let old_interest

    const interest = parseFloat(COMMISSIONS[amount][parseFloat(term)])

    if (DEFAULT_COMMISSIONS[amount])
      old_interest = parseFloat(DEFAULT_COMMISSIONS[amount][parseFloat(term)])
    else
      old_interest = interest + 100000

    const old_total = amount + old_interest
    let pre_total = parseFloat(amount)
    let total = pre_total + (pre_total * FEE_AMOUNT / 100)

    if (this.fdfIsAgreed?.checked) total += FDF_AMOUNT

    if (this.calcAmount) this.calcAmount.innerText = `${amount.format(0, ',', ' ')} `
    if (this.calcAmountSum) this.calcAmountSum.innerText = `${amount.format(0, ',', ' ')} `

    if (this.calcInterest) this.calcInterest.innerText = `${interest.format(0, ',', ' ')} `
    if (this.oldCalcTotal) this.oldCalcTotal.innerText = `${old_total.format(0, ',', ' ')} `
    if (this.calcTotal) this.calcTotal.innerText = `${total.format(0, ',', ' ')} `

    if (this.calcHandingFee) this.calcHandingFee.innerText = `${handingFee.format(0, ',', ' ')} `
    if (this.forTerms) this.forTerms.innerText = term
  }

  amountSliderChanging(sliderValues) {
    const newValue = this.amountScale(LOAN_VALUES, sliderValues.newValue)
    this.timeout()

    const top = document.querySelector('[data-ref="slider-top"]')
    if (newValue > CONDITIONAL_VALUE) {
      this.creditLimitation.dataset.visible = 'true'
      if (top) top.dataset.forbidden = 'true'
      this.amount_slider_move_to_repeat()
    } else {
      delete this.creditLimitation.dataset.visible
      if (top) delete top.dataset.forbidden
    }

    let needAmount = newValue

    let feeAmount = needAmount * FEE_AMOUNT / 100

    if (this.fdfIsAgreed?.checked) needAmount += FDF_AMOUNT

    if (this.needAmount) this.needAmount.innerText = `${needAmount.format(0, ',', ' ', true)} `
    if (this.feeAmount) this.feeAmount.innerText = `${feeAmount.format(0, ',', ' ', true)} `
    if (this.needAmountMobile) this.needAmountMobile.innerText = `${needAmount / 1000000} `
    this.calculatePaymentDueDate()
    this.countCalcValues({ amount: newValue })
    this.amount_slider_move()

    if (this.defaultAmountWas) this.setDefaultAmountMoved(Number(this.defaultAmountWas.value), newValue)
  }

  termSliderChanging(sliderValues) {
    const newValue = this.termScale(TERM_VALUES, sliderValues.newValue)
    this.countCalcValues({ term: newValue })
    this.calculatePaymentDueDate(newValue)

    this.totalValue.classList.add('green')
    this.timeout()

    this.term_slider_move()
  }

  amountScale(array, value) {
    return this.scale(array, value)
  }

  termScale(array, value) {
    return this.scale(array, value)
  }

  scale(array, value) {
    return array.reduce((prev, curr) => {
      return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    })
  }
}
