import Cookies from 'js-cookie'
const { get, set, remove } = Cookies
const { translations } = window.gon

export default class LibCheckAgreementNumber {
  constructor(container) {
    this.documentNumber = container.querySelector('[role="documentNumber"]')
    this.checkAgreementNumberBtn = container.querySelector('[role="checkAgreementNumber"]')
    this.form = container.querySelector('[role="form"]')
    this.agreementNumberInfo = container.querySelector('[role="agreementNumberInfo"]')

    $(this.form).validate({
      errorClass: 'form_float__error_dark-theme',
      rules: {
        'pawnshop_form[document_number]': {
          required: true,
          exactlength: [9, 12],
        }
      },
      messages: {
        'pawnshop_form[document_number]': {
          required: translations['errors.messages.documentNumber'],
          exactlength: translations['errors.messages.documentNumberLength'],
        }
      }
    })

    new Inputmask({
      mask: "(999 999 999 [9])|(999 999 999 999)",
      showMaskOnHover: false,
      greedy: false,
    }).mask(this.documentNumber)

    if (get('agreementTimerInterval') > 0) this.renderWaitingScreen()

    $(this.checkAgreementNumberBtn).click(e => {
      if ($(this.form).valid() && !get('agreementTimerInterval')) {
        this.checkAgreementNumber()
      } else {
        e.preventDefault()
      }
    })
  }

  renderWaitingScreen = () => {
    $.ajax({
      url: '/agreements/check_number',
      type: 'GET',
      data: {
        timer: true
      },
      success: data => {
        $(this.agreementNumberInfo).html(data.html)

        this.startTimer()
      }
    })
  }

  startTimer = () => {
    let timeinterval

    timeinterval = setInterval((function() {
      const newTime = get('agreementTimerInterval') - 1

      if (newTime > 0) {
        $('#waiting_seconds b').text(newTime)
        return set('agreementTimerInterval', newTime)
      } else {
        $('[role="agreementInfoBlank"]').hide()
        remove('agreementTimerInterval')
        return clearInterval(timeinterval)
      }
    }), 1000)
  }

  checkAgreementNumber = () => {
    $.ajax({
      url: '/agreements/check_number',
      type: 'GET',
      data: {
        document_number: $(this.documentNumber).val()
      },
      success: data => {
        $(this.agreementNumberInfo).html(data.html)
        set('agreementTimerInterval', data.seconds)

        this.startTimer()
      }
    })
  }
}
