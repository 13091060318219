export default class LibCmndPart {
  constructor(container) {
    this.container = container
    this.cmndButton = container.querySelector('[data-ref="cmnd_part_show"]')
    this.cmndHiddenText = container.querySelector('[data-ref="cmnd_part_info"]')

    if (this.cmndButton) this.cmndButton.addEventListener('click', () => this.clickCmndButton())
  }

  clickCmndButton() {
    if (!this.cmndHiddenText) return

    if (this.cmndHiddenText.dataset?.hidden) {
      delete this.cmndHiddenText.dataset.hidden
      this.cmndButton.remove()
      // this.cmndHiddenText.after(this.cmndButton)
    } else {
      this.cmndHiddenText.dataset.hidden = 'true'
      // this.cmndHiddenText.before(this.cmndButton)
    }
  }
}
