export default class LibScamWarning {
  constructor(container) {
    this.container = container
    this.scamButton = container.querySelector('[data-ref="scam_button"]')
    this.scamHiddenText = container.querySelector('[data-ref="scam_info"]')

    if (this.scamButton) this.scamButton.addEventListener('click', () => this.clickScamButton())
  }

  clickScamButton() {
    if (!this.scamHiddenText) return

    if (this.scamHiddenText.dataset?.hidden) {
      delete this.scamHiddenText.dataset.hidden
      this.scamButton.textContent = 'Ít hơn'
      this.scamHiddenText.after(this.scamButton)
    } else {
      this.scamHiddenText.dataset.hidden = 'true'
      this.scamButton.textContent = 'Tìm hiểu thêm'
      this.scamHiddenText.before(this.scamButton)
    }
  }
}
