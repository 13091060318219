export default class LibSubscribeNotifications {
  constructor() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(sw => {
        if (Notification.permission === 'denied') {
          console.warn("Notification permission denied")
        } else {
          sw.pushManager.getSubscription().then(sub => {
            if (!sub) {
              sw.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: new Uint8Array(gon['publicKey'])
              }).then(sub => {
                if (sub) {
                  $.ajax({
                    url: location.origin + "/webpush/subscriptions",
                    type: "POST",
                    data: sub.toJSON()
                  })
                }
              })
            }
          })
        }
      })
    }
  }
}
