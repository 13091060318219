export default class LibUpdateJeffParams  {
  constructor(container, options) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const moon_data = window.gon.application
    this.update_local_storage(moon_data)

  }

  update_local_storage(moon_data) {

    const browserStorageList = JSON.parse(localStorage.getItem('browser_storage_list')) || {}
    const data = {}
    for (var key in moon_data) {
      if (moon_data.hasOwnProperty(key)) {
        const expiry = new Date()
        expiry.setMonth(expiry.getMonth() + 1)
        const new_key = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        data[`application[${new_key}]`] = {
          value: moon_data[key],
          expiry
        }
      }
    }
    Object.assign(browserStorageList, data)
    localStorage.setItem('browser_storage_list', JSON.stringify(browserStorageList))
  }
}
