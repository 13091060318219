import Cookies from 'js-cookie'
const { set, get } = Cookies

export default class LibFirstPageTimer {
  constructor() {
    set('timeToPressBorrow', 0)

    const timer = () => set('timeToPressBorrow', Number(get('timeToPressBorrow')) + 1)

    const counter = setInterval(timer, 1000)

    $('.container').submit(() => clearInterval(counter))
  }
}
